#heading {
  #heading-sub-title {
    font-size: 2.2rem;
    font-style: italic;
  }
  #heading-title {
    font-size: 3.5rem;
    font-weight: 500;
  }
}
