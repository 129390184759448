@import "variables";

/* Default styles for all screen sizes */

/* Styles for 27-inch monitors and larger */
@media screen and (min-width: 2560px) {
  html {
    font-size: 13px;
  }
}

/* Styles for 24-inch monitors */
@media screen and (min-width: 1920px) and (max-width: 2559px) {
  html {
    font-size: 12px;
  }
}

/* Styles for 16-inch laptops */
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  html {
    font-size: 10px;
  }
}

/* Styles for 13-inch laptops */
@media screen and (min-width: 1066px) and (max-width: 1599px) {
  html {
    font-size: 9px;
  }
}

/* Styles for iPads */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  html {
    font-size: 8px;
  }
}

/* Styles for iPhones */
@media screen and (max-width: 767px) {
  html {
    font-size: 4.5px;
  }
}

html {
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.App {
  scroll-behavior: smooth;
}

section {
  padding: 1rem;
  height: 100vh;
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}

@font-face {
  font-family: "Montserrat";
  src: url("fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
    format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "Jersey 25";
  src: url("fonts/Jersey_25/Jersey25-Regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

body {
  font-family: "Montserrat", sans-serif;
}

.bg-warning {
  background-color: $main-color3 !important;
}

.border-warning {
  border-color: $main-color3 !important;
}

.text-warning {
  color: $main-color3 !important;
}

iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 8 / 3;
  border: 2px solid $main-color2;
}
