@import "variables";

#front {
  font-size: 3rem;
  height: 100vh;
  background-color: $main-color1;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */

  background-image: linear-gradient(
    155deg,
    rgba($main-color1, 0.3),
    rgba($main-color3, 0.9)
  );
}

#front-text-buttons {
  text-align: left;
  width: 60%;
  #front-text {
    .hello-text {
      font-size: 6rem;
      font-weight: bold;
      font-family: "Montserrat", sans-serif;

      color: $main-color3;
    }
    .title-text {
      font-size: 4rem;
      .title,
      .cursor {
        font-family: "Jersey 25", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 5rem;
      }
    }
  }

  #front-buttons {
    .btn {
      margin-right: 2rem;
      margin-top: 4rem;
      font-weight: 500;
      padding: 2rem !important;
      font-size: 1.5rem;
    }
    .btn > a {
      color: $main-color3;
      font-size: 1.5rem;
    }
  }
}
